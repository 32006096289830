<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="'100hv'">
      <template v-slot:title>
        Выбор позиций, ожидающих приёмки {{ getAccess("documents.goodsIncome.fullIncomeList") ? "[Полный доступ]" : "[Не полный доступ]" }}
      </template>
      <v-card-text style="height: 80vh; overflow: auto" class="pa-0">
        <a-table-f-sql
          ref="table"
          :query="sql"
          :model="model"
          :useQuery="false"
          :defaults="{ paramName: 'goods.goodsIncome', sort: {} }"
          @click="clickRow"
          @change-filter="onInputFilter($event)"
        />
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="getAccess('documents.goodsIncome.create')" @click="add()" :title="'Принять выбранное'" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess],
  props: {
    value: Boolean,
    storage: Object,
    order_id: Number,
    type: { type: String, default: "AddFromIncome" },
  },
  data() {
    return {
      url: "/accounting/doc/goods_income",
      dataTable: [],
      sql: {},
      filterSql: "",
      filterData: { objects: [], storages: [] },
    };
  },
  computed: {
    filter: {
      get() {
        let res = this.$store.getters["config/getLocalParams"]?.["goodsIncomeList.filter"] || -1;
        return res;
      },
      set(v) {
        this.$store.commit("config/setLocalParams", {
          name: "goodsIncomeList.filter",
          value: v,
        });
      },
    },
    model() {
      let model = JSON.parse(JSON.stringify(this.$store.getters["config/get"].models.documentOrderGoods.listDetail));
      model.find((m) => {
        return m.name == "name";
      }).style = "white-space: normal;";
      model = [
        { name: "checked", type: "checkbox", width: 35, hidden: !this.getAccess("documents.goodsIncome.create") },
        { name: "name_doc", title: "Заказ товаров", type: "string", width: 135, style: "white-space: normal;" },
        {
          name: "object_name",
          title: "Для объекта...",
          type: "string",
          style: "white-space: normal;",
          filter: { name: "object_code", type: "selectsql", sql: this.filterSql.objects },
          sortable: true,
          width: 150,
        },
        {
          name: "store_name",
          title: "Для склада...",
          type: "string",
          style: "white-space: normal;",
          filter: { name: "store_id", type: "selectsql", sql: this.filterSql.storages },
          sortable: true,
          width: 150,
        },
        { name: "date_before", title: "Дата ожид.", type: "date", width: 80 },
        ...model.filter((el) => {
          return ["id", "name", "amount", "measure", "price_real"].includes(el.name);
        }),
        {
          name: "doc_goods_income_id",
          title: "Приемка в",
          type: "selectapi",
          itemField: { value: "id", text: "code_doc" },
          api: "accounting/doc/goods_income",
          preText: "Приемка №",
          style: "white-space: normal;",
          sortable: true,
          width: 150,
          style: "text-align:left;",
        },
      ];
      return model;
    },
  },
  created() {
    this.getData();
  },
  watch: {
    value() {
      if (this.value) {
      }
    },
  },
  methods: {
    onInputFilter(f) {
      console.log("filter", f);
    },
    getData() {
      let order = this.order_id ? " and og.id=" + this.order_id : "";
      let access = "";
      if (!this.getAccess("documents.goodsIncome.fullIncomeList")) {
        access = ` and (og.type=1 and og.object_code in (select object_id from objects_access where user_id=${this.$root.profile.id}) )`;
      }
      let forStorage = "";
      //если принимается на склад объекта, то оттображать только товары заказанные на этот склад
      if (this?.storage && this.storage.object_id) {
        forStorage = ` and og.object_code=${this.storage.object_id}`;
      }
      let sql = `
select *, if(doc_goods_income_id is null ,0,1) as check_disable from (
SELECT ogd.id,
  ogd.amount_real-ifnull(ogd.amount_income,0) as amount,
  ogd.price_real,
  ogd.name , 
  ogd.measure,
  concat('Заказ товаров № \n',og.code_doc,' ',left(og.date_doc,10)) as name_doc,og.date_doc,og.type, 
  og.object_code, 
  (select n.name from objects n where n.id=og.object_code) as object_name,
  og.store_id,
  (select n.name from accounting_dir_storage n where n.id=og.store_id) as store_name,
  og.id as order_goods_id,
  ogd.date_before,
  (select gi.id from accounting_doc_goods_income_table git 
    inner join accounting_doc_goods_income gi on gi.id=git.parent_id 
    where gi.status=1 and git.accounting_doc_order_goods_table_id=ogd.id limit 1) as doc_goods_income_id
FROM accounting_doc_order_goods as og 
  left join accounting_doc_order_goods_table as ogd on ogd.parent_id=og.id 
WHERE ogd.status <> 1 and og.status=2 and og.type in (1,3) 
${forStorage}
${order} 
${access}
) as t
`;
      this.filterSql = {
        objects: `select distinct og.object_code as 'value',(select n.name from objects n where n.id=og.object_code) as 'text'
FROM accounting_doc_order_goods as og left join accounting_doc_order_goods_table as ogd on ogd.parent_id=og.id 
WHERE ogd.status <> 1 and og.status=2 and og.type = 1 
${forStorage}
${order} 
${access}`,
        storages: `select distinct  og.store_id as 'value', (select n.name from accounting_dir_storage n where n.id=og.store_id) as 'text'
FROM accounting_doc_order_goods as og left join accounting_doc_order_goods_table as ogd on ogd.parent_id=og.id 
WHERE ogd.status <> 1 and og.status=2 and og.type =3
${forStorage}
${order} 
${access}`,
      };
      this.sql = { sql, order: "if(date_before IS null,1,-1), date_before , date_doc, id" };
      sql += "order by if(date_before IS null,1,-1), date_before , date_doc, id";
      return;
    },
    clickRow(d) {
      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row);
      this.$refs.table.$refs.base.$forceUpdate();
    },
    add() {
      let rows = [];
      let order = null;

      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        if (!order) order = row.order_goods_id;
        if (order !== row.order_goods_id) {
          this.$root.$emit("show-info", {
            type: "error",
            text: `Принимаемые товары должны быть из одного заказа товаров `,
          });
          return;
        }
        let comments = null;
        if (row.store_name) comments = `для склада ${row.store_name}`;
        if (row.object_name) comments = `для объекта ${row.object_name}`;
        rows.push({
          accounting_doc_order_goods_table_id: row.id,
          name: row.name,
          amount: row.amount,
          measure: row.measure,
          value: row.price_real,
          order_goods_id: row.order_goods_id,
          comments,
          object_id: parseInt(row.object_code),
          store_id: row.store_id,
        });
      }
      if (rows.length == 0) return;
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>
